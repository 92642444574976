<template>

  <div id="SlideOptions_body">

    <loader v-if="showLoader" />

    <!-- <div v-if="selectedMenu" id="promocional_container">
      <p>Desde</p>
      <p id="promotional_price">
        MX$<span class="promotion_tag_price">{{ selectedMenu.title_1_attractive_payment }}</span>/{{ selectedMenu.title_1_payment_frecuency }}
      </p>
      <p v-if="selectedMenu.id != 7" class="promotion_tag_conditions">
        (Pagando Anualmente)
      </p>
    </div> -->


    <div id="product_packages" class="z-10">

      <div v-if="!menuListData.length" class="text-center rounded-md p-3 flex justify-center items-center text-primary">
          Has agregado todos nuestros servicios para realizar una cotización
      </div>
      <!-- <div class="absolute flex h-full flex-col justify-center items-center" >
        <div>Selecciona algunas opciones de cotización</div>
      </div> -->

      
      <transition-group
        name="list-complete-to-down"
        tag="p"
        class="flex flex-wrap justify-center"
      >
        <div
          v-for="item in menuListData"
          :key="item.id"
          class="list-complete-to-down-item relative"
          @click="addToSelected(item)"
        >
          <img
            :src="item.image_xs_primary"
            width="40"
            height="40"
            :class="[
              selectedMenu && selectedMenu.id == item.id
                ? 'selected'
                : 'main-item-image'
            ]"
          />
          <transition name="fade-pop">
            <span
              v-if="selectedMenu && selectedMenu.id == item.id"
              class="absolute top-0 right-0 bg-white rounded-full h-5 w-5 flex justify-center items-center"
            >
              <font-awesome-icon
                :icon="['fas', 'plus-circle']"
                class="fa-1x text-secondary"
              />
            </span>
          </transition>

        </div>
      </transition-group>

      
      <h1 class="dialog" v-if="!selectedProductCategories.length">
        Selecciona algunas opciones de cotización
      </h1>

    </div>


    <transition>
      


      <div
        v-if="selectedMenu"
        :key="selectedMenu.id"
        id="product_coverages"
      >
      
        <div>

          <div id="more_about_phone" @click="openManual">Saber Más</div>

          <div id="title_container">
            <p>
              {{ selectedMenu.title_1.join(' ') }}
            </p>
          </div>
          <p id="subtitle_container">
            {{ selectedMenu.title_1_description.join(' ') }}
          </p>
        </div>


        <div>
          <div id="coverage_title">
            {{ selectedMenu.title_2.join(' ') }}
          </div>

          <div id="coverage_detail">
            <ul>
              <template v-for="(coverage) in selectedMenu.title_2_description.join(' ').split('-')">
                <li
                  :key="coverage"
                  v-if="coverage"
                >
                  -{{ coverage }}
                </li>
              </template>
            </ul>
          </div>
        </div>

        <div id="coverage_pet">
          <img
            :src="selectedMenu.image_xs_secondary"
            alt=""
            height="120"
            width="120"
          />
        </div>
              
        <!--         
        <div id="add_insurance">
          <button
            class="bg-special-3"
            @click="addToSelected(selectedMenu)"
          >
            {{ selectedProductCategories.filter((item) => { return selectedMenu && item.id == selectedMenu.id }).length ? 'Agregado': 'Agregar'}}
          </button>
        </div> 
        -->


      </div>

    </transition>

    <h1 class="sp">-</h1>


    <div id="selected_products" class="z-10">

      <div v-if="validateInactiveInsurances()" id="insurances_selected" style="display:flex; flex-direction:column;">

        <!-- Imagen de Cotización -->
        <transition-group
          name="list-complete-to-top"
          tag="p"
          class="flex flex-wrap justify-center"
        >

          <div
            v-for="item in selectedProductCategories"
            :key="'selected-' + item.id"
            class="list-complete-to-top-item relative"
            @click="productCategories.removeItem(item); selectedMenu = item;"
          >
            <img
              :src="item.image_xs_primary"
              width="40"
              height="40"
            />
            <span
              class="absolute top-0 right-0 bg-white rounded-full h-5 w-5 flex justify-center items-center"
            >
              <font-awesome-icon
                :icon="['fas', 'times']"
                class="fa-1x cursor-pointer text-primary"
              />
            </span>
          </div>

        </transition-group>

        <!-- Boton de Cotización -->
        <div class="bg-blur">
          <button
            v-if="selectedProductCategories.length"
            class="rounded-md focus:outline-none text-white flex justify-center items-center text-2xl text-center border-2 border-primary btn bg-special-1 w-full"
            @click="clickBacklog('quote_radial_view');"
          >
            Cotizar
            <font-awesome-icon
              :icon="['fas', 'arrow-right']"
              class="fa-1x absolute right-4 animate-goto"
            />
          </button>
        </div>

      </div>

      <div v-else id="whatsapp_container_app">
        
        <h1>Este seguro requiere mayor información, iniciemos conversación</h1>
        <h3>*Escribe tus Datos de Contacto</h3>

        <div id="whatsapp_container_secondary">
          <svg id="whatsapp_message_button" class="w-12 h-46 text-green-400 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path></svg>
          <input id="whatsapp_message_input" type="text" placeholder="Escribe un mensaje"
            v-model="message_whatsapp_data"
          >
          <!-- WORKING ON IT -->
          <a :href="api_whatsapp_data + '?phone='+phone_whatsapp_data + '&text=' + 'Seguros Interes:' + selectedProductCategoriesNames + '         Cliente:' + message_whatsapp_data "
            target="_blank"><font-awesome-icon
            :icon="['fas', 'paper-plane']"
            @click="
              send_message_whatsapp();
            "
            class="fa-lg text-primary cursor-pointer send_message_whatsapp"
          />
          </a>
        </div>
      </div>

    </div>

  </div>
    
</template>

<script>

import { mapGetters } from 'vuex';
import { whatsapp } from '../../../constants/config';
import Loader from "@/components/Loader.vue"


export default {
  name: "slideOptions",
  data: () => ({

    showLoader: false,
    selectedProductCategoriesNames:'',
    inactive_insurances : [],
    window: {
      width: 0,
      height: 0
    },
    menuList: [],
    selectedMenu: null,
    applyDelay: true, // Activa un delay para aplicarlo al iniciar la expansión del menú
    elipseWRadius: 300, // Define el ancho del menú
    api_whatsapp_data : 'https://api.whatsapp.com/send',
    phone_whatsapp_data: whatsapp,   
    message_whatsapp_data:'',   
    currentState:  {
      id: 0,
      description: "El componente está en una escala de 0, por lo que no se muestra aún."
    },
    componentStates: {
      0: {
        id: 0,
        description: "El componente está en una escala de 0, por lo que no se muestra aún."
      },
      1: {
        id: 1,
        description: "El componente se muestra activando la animación de inicio."
      },
      2: {
        id: 2,
        description: "El componente del menu radial se reduce, pero no los botónes de items seleccionados."
      },
      3: {
        id: 3,
        description: "Se desaparecen los indicadores de items seleccionados."
      }
    },

  }),
  components: {
    Loader
  },
  props: ["stepProgress", "isInit"],
  computed: {
    ...mapGetters(["productCategories"]),
    menuListData() {

      try {
        let self = this;
        return this.menuList.filter(item => {
          return !self.selectedProductCategories.filter(selected => item.id == selected.id)
            .length;
        })
      } catch (error) {
        return []
      }
      
    },
    selectedProductCategories() {
      try {
        var response = this.productCategories.getUserSelection();
        return response 
      } catch (error) {
        return []
      }
    },
  },
  mounted() {
    this.showLoader = true
    
    setTimeout(() => {
      this.setInitialState()
      this.showLoader = false
    }, 2000)
  },
  methods: {
    start() {
      // console.log("Comenzando la animación de entrada");
      let self = this;
      setTimeout(() => {
        self.currentState = self.componentStates[1];
      }, 500);
    },
    end() {

      //TODO WAIT TO DELETE
      // let self = this;
      // self.currentState = self.componentStates[2];
      setTimeout(() => {
        // self.currentState = self.componentStates[3];
        this.$emit("next");
      }, 1000);

    },
        
    setInitialState() {
      let self = this;

      // Veryfying Active Insurances
      let productCategories = this.productCategories.getItems();
      productCategories.forEach(product => {
        if(!product.is_active){
          this.inactive_insurances.push(product.id)
        }
        // var is_active = (product.id, product.is_active)
      });
      // console.log(JSON.stringify(this.inactive_insurances))

      self.menuList = self.productCategories.getItems().map(
        function(item) {
          return {
            ...item,
            selected: self.selectedProductCategories.filter((x) => x.id == item.id).length,
            x: 0,
            y: 0,
            style: "",
          }
        }
      )
      this.currentState = this.componentStates[0];
    },

    addToSelected(item){


      if (this.selectedMenu){
        if (this.selectedMenu.id == item.id) {
          let hasBeenAddded = this.selectedProductCategories.filter(function(element){return element.id == item.id})
          if (!hasBeenAddded.length) {
            this.selectedProductCategories.push(item);
            this.selectedProductCategoriesNames = ""
            this.selectedProductCategories.forEach(insurances => {
              this.selectedProductCategoriesNames += `(${insurances.name})`
            })
          }
          this.updateSelected(item);
          item.selected = true;

          window.scroll({
            top: 10000,
            behavior: 'smooth'
          })

        } else {
          this.selectedMenu = item;
        }
      } else {
        this.selectedMenu = item;
      }
      
      this.menuList.forEach((selectedInsurance,index) => {
        if(selectedInsurance.id != item.id){
          this.productCategories.removeItem(selectedInsurance)
        }
      })

      this.selectedProductCategories.push(item)

    },
    updateSelected(item) {

      // Función Especifica para deshabilitar la selección multiple
      this.menuList.forEach((selectedInsurance,index) => {
        if(selectedInsurance.id != item.id){
          this.productCategories.removeItem(selectedInsurance)
        }
      })
      
      this.$emit("choiced", { choiced: this.selectedProductCategories });
    },
    setCurrentSection(item) {
      this.selectedMenu = item;
    },

    validateInactiveInsurances(){

      var response = true
      var selectedProductCategories = this.selectedProductCategories.map(item => item.id)
      var inactive_insurances = this.inactive_insurances
      
      // console.log(selectedProductCategories)
      // console.log(selectedProductCategories)
      // console.log(inactive_insurances)

      inactive_insurances.forEach(inactive_insurance => {
        if(selectedProductCategories.includes(inactive_insurance)){
          response = false
        }
      });

      return response

    },

    openManual() {

      var category_id = this.selectedMenu.id
      var manuals_names = {
        // Accidentes Personales
        "1" : "media/Thona/Generales_Thona_AccidentesPersonales.pdf",

        // Bicicletas
        "2" : "media/Primeros_Seguros/Generales_PrimerosSeguros_Bicicleta.pdf",

        // Cáncer
        "3" : "media/THB_General/Folleto_Cnsf_THB.pdf",

        // COVID
        "4" : "media/Thona/Generales_Thona_Covid.pdf",

        // Dental
        "5" : "media/Dentegra/Generales_Dental_Dentegra.pdf",

        // Funerario
        "6" : "media/Thona/Generales_Thona_Funerario.pdf",

        // Gastos Médicos
        "7" : "media/THB_General/Folleto_Cnsf_THB.pdf",

        // Hogar
        "8" : "media/Chubb/Generales_Chubb_Hogar.pdf",

        // Mascotas
        "9" : "media/Gmx/Generales_Gmx_Mascota.pdf",

        // Pyme
        "10" : "media/THB_General/Folleto_Cnsf_THB.pdf",

        // Quirúrgico
        "11" : "media/Thona/Generales_Thona_Quirurgico.pdf",

        // Accidentes en Viajes
        "12" : "media/Continental/Generales_Continental_Viajero.pdf",

        // VRIM
        "13" : "media/VRIM/Generales_VRIM_Producto.pdf",
      }

      var manual_name = manuals_names[category_id]
      var path = this.$APIServices.endpoint + manual_name
      window.open( path , '_blank' );
    },

    async clickBacklog(place){


      var wordpress_redirect_array = this.productCategories.userSelection.filter(item => item.id == 9)

      if(wordpress_redirect_array.length != 0){

        window.open("https://sabar.mx/seguro-mascotas/" , '_blank')
      
      } else {

        // var insurance_selected = this.productCategories.userSelection[0].id
        // var IdsInsurances = {
        //   "1":"accidentes-personales",
        //   "2":"bicicleta",
        //   "5":"dental",
        //   "6":"funerario",
        //   "8":"hogar",
        //   "9":"mascota",
        //   "11":"quirurgico",
        //   "12":"viajes",
        //   "13":"vrim",
        // }
        // window.open(`https://seguros.sabar.mx/seguro/${IdsInsurances[insurance_selected]}` , '_blank' );




        
        
        var insurances_selected = this.productCategories.userSelection
        var insurances_selected_text = ""
        insurances_selected.forEach(insurances_selected => {
          insurances_selected_text+= (insurances_selected.name+",")
        })
        
        var url = this.$APIEndpoint + this.$APIServices.click_watcher
        var body = {
          "place": place,
          "comments": `Seguros Seleccionados: ${insurances_selected_text}`,
          "category_ids":[1,2,3]
        }

        await this.$http.post(url , body , this.$auth
        ).then(() => {
          // console.log("Result:", result);
        }).catch((error) => {
          console.log(error);
        }).finally(() => {
          this.end();
        })
        
      }

    }

  }
};
</script>


<style>

.main-item-image {
  filter: grayscale(100%);
}

img.selected {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: scale(1.3);
}





  #emit_package_selectedContainer{
    position: relative;
  }

</style>